@font-face {
  font-family: 'Munken Sans'; /*a name to be used later*/
  src: url('MunkenSansWeb-Regular.woff2'); /*URL to font*/
}

html, body {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
  width: 100%;
  margin: 0;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: gray transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
  border: 3px solid transparent;
}