.spinner {
    width: 100%;
    height: 100%;
}
  
.container {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    filter: url('#goo');
    animation: rotate-move 2s ease-in-out infinite;
}
  
.dot { 
    width: 80px;
    height: 80px;
    border-radius: 40%;
    background-color: #000;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
  
.dot-6 {
    background-color: #eb5c61;
    animation: dot-6-move 2s ease infinite, index 12s ease infinite;
}
  
.dot-5 {
    background-color: #1d9798;
    animation: dot-5-move 2s ease infinite, index 12s -10s ease infinite;
}
  
.dot-4 {
    background-color: #99bca8;
    animation: dot-4-move 2s ease infinite, index 12s -8s ease infinite;
}
  
.dot-3 {
    background-color: #f9d663;
    animation: dot-3-move 2s ease infinite, index 12s -6s ease infinite;
}
  
.dot-2 {
    background-color: #f38f22;
    animation: dot-2-move 2s ease infinite, index 12s -4s ease infinite;
}
  
.dot-1 {
    background-color: #f7bbb3;
    animation: dot-1-move 2s ease infinite, index 12s -2s ease infinite;
}
  
@keyframes dot-6-move {
    20% {transform: scale(1)}
    45% {transform: translateY(18px) scale(.5)}
    60% {transform: translateY(90px) scale(.3)}
    80% {transform: translateY(90px) scale(.3)}
    100% {transform: translateY(0px) scale(1)}
}
  
@keyframes dot-5-move {
    20% {transform: scale(1)}
    45% {transform: translate(-16px, -12px) scale(.5)}
    60% {transform: translate(-80px, -60px) scale(.6)}
    80% {transform: translate(-80px, -60px) scale(.6)}
    100% {transform: translateY(0px) scale(1)}
}
  
@keyframes dot-4-move {
    20% {transform: scale(1)}
    45% {transform: translate(16px, -12px) scale(.5)}
    60% {transform: translate(80px, -60px) scale(.4)}
    80% {transform: translate(80px, -60px) scale(.4)}
    100% {transform: translateY(0px) scale(1)}
}
  
@keyframes dot-3-move {
    20% {transform: scale(1)}
    45% {transform: translateY(-18px) scale(.5)}
    60% {transform: translateY(-90px) scale(.3)}
    80% {transform: translateY(-90px) scale(.3)}
    100% {transform: translateY(0px) scale(1)}
}
  
@keyframes dot-2-move {
    20% {transform: scale(1)}
    45% {transform: translate(-16px, 12px) scale(.5)}
    60% {transform: translate(-80px, 60px) scale(.4)}
    80% {transform: translate(-80px, 60px) scale(.4)}
    100% {transform: translateY(0px) scale(1)}
}

@keyframes dot-1-move {
    20% {transform: scale(1)}
    45% {transform: translate(16px, 12px) scale(.5)}
    60% {transform: translate(80px, 60px) scale(.6)}
    80% {transform: translate(80px, 60px) scale(.6)}
    100% {transform: translateY(0px) scale(1)}
}
  
@keyframes rotate-move {
    55% {transform: translate(-50%, -50%) rotate(0deg)}
    80% {transform: translate(-50%, -50%) rotate(360deg)}
    100% {transform: translate(-50%, -50%) rotate(360deg)}
  }
  
@keyframes index {
    0%, 100% {z-index: 6}
    16.6% {z-index: 5}
    33.3% {z-index: 4}
    50% {z-index: 3}
    66.6% {z-index: 2}
    83.3% {z-index: 1}
}